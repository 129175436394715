import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = { class: "company-info" }

import AppHeading from '@/components/HeadingComponent.vue';
import { LoginAuditLog } from '@/models/AuditLog';
import { userService } from '@/services/UserService';
import { ServiceError } from '@/services/util/ServiceError';
import { onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import dateFormat from '@/helpers/DateFormat.helper';
import { accountService } from '@/services/AccountService';
import { useProfileStore } from '@/store/profile.module';
import Account from '@/models/Account';


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersAuditPage',
  setup(__props) {

const storeProfile = useProfileStore();

const route = useRoute();
const account: Ref<Account | undefined> = ref();
const auditLogs: Ref<LoginAuditLog[] | undefined> = ref();

onMounted(async () => {
  await loadLoginAuditLogs();
  await getAccountInfo();
});

const loadLoginAuditLogs = async () => {
  const result = await userService.findAllLoginAuditLog();
  if (!(result instanceof ServiceError)) {
    auditLogs.value = result;
  }
};

const getAccountInfo = async () => {
  const result = await accountService.find(storeProfile.accountId);
  if (!(result instanceof ServiceError)) {
    account.value = result;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AppHeading, {
      title: _ctx.$t(_unref(route).meta.breadcrumb ? _unref(route).meta.breadcrumb[0].label : '')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, [
          _cache[0] || (_cache[0] = _createElementVNode("i", {
            class: "icon-laptop",
            "aria-hidden": "true"
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(account.value?.businessName), 1)
        ])
      ]),
      (auditLogs.value)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            ref: "dt",
            value: auditLogs.value,
            "data-key": "id",
            rows: 10,
            "filter-display": "menu",
            "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
            paginator: true,
            "responsive-layout": "scroll"
          }, {
            empty: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('table.not-found.users')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "email",
                header: _ctx.$t('auditLog.email'),
                style: {"width":"25%"}
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "ip",
                header: _ctx.$t('auditLog.ip'),
                style: {"width":"35%"}
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "date",
                header: _ctx.$t('auditLog.date'),
                style: {"width":"15%"}
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_unref(dateFormat).formatDate(_unref(dateFormat).formatLocalTimezone(slotProps.data.date))), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "date",
                header: _ctx.$t('auditLog.hour'),
                style: {"width":"15%"}
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_unref(dateFormat).formatDateOnlyHourWithSeconds(_unref(dateFormat).formatLocalTimezone(slotProps.data.date))), 1)
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }, 8, ["value"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})